import React from 'react';
import { NavLink } from 'react-router-dom';


export const Footer = () => {
    return (
          <footer>
              <div>
                  <small>
                    &#169;	{ new Date().getFullYear() } Pace Capital Management 
                  </small>
              </div>

              <div>
              <ul>
                <li>
                <NavLink exact to='/'> HOME</NavLink>
                </li>
                <li>
                <NavLink exact to='/about'> ABOUT US</NavLink>
                </li>
                <li>
                <NavLink exact to='/contact'> CONTACT</NavLink>
                </li>
            </ul>
              </div>
          </footer>
    )
}