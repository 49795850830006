import React from 'react';
import { Logo } from './Logo';
import { Footer } from './Footer';

export const Contact = ({ nav }) => {
  return (
    <main>
      <Logo />
      {nav}
      <section className="contactContainer banner">
        <div className="overlay"></div>
        <br />
        <div className="grid grid-1 center">
          <div className="contact-banner">
            <h1>CONTACT US</h1>
            <img src="/assets/contactBanner.svg" alt="banner" width="100%" />
          </div>
        </div>
      </section>

      <section style={{padding: '3%'}} className="center">
        <br />
        <br />
        <p>Contact us for all inquiries including but not limited to investor relations and career interests.</p>
      </section>
      <section className="center contactInfo">
        <div className="grid grid-3">
          <div className="card">
            <h3>EMAIL</h3>
            <p>support@pacecapitalmgt.com</p>
          </div>
          <div className="card">
            <h3>PHONE</h3>
            <p>
              469-892-8003</p>
          </div>
          <div className="card">
            <h3>MAIL TO</h3>
            <p >
              101 E. Park Blvd Suite 600 Plano, TX 75074 
              </p>
          </div>
        </div>
      </section>
      <Footer/>
    </main>
  );
}