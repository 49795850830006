import React from 'react';
import { Footer } from './Footer';
import { Logo } from './Logo';
import { Link } from 'react-router-dom';

export const Home = ({ nav }) => {
  return (
    <main>
      <Logo/>
      {nav}
      <section className="banner">
        <div className="anchor" onClick={() => {
              if(window.scroll){
                let el = document.querySelector('.bar');

                if(el && el.scrollIntoView){
                  el.scrollIntoView({ behavior: 'smooth'})
                }
              }
          }}>
          <div className="anchor-inner"></div>
        </div>
        <div className="overlay"></div>
        <br />
        <div className="grid grid-2 gap-5">
          <div className="card-banner">
            <h1 className="font-38">PROPRIETARY TRADING DESK</h1>
            <p>
              We develop technology and systematic financial strategies to capture new opportunities.
                  </p>
          </div>
          <div className="banner-image">
            <img src="/assets/banner.svg" alt="banner" width="100%" />
          </div>
        </div>
      </section>
      <section className="section center">
        <div className="bar center">
          <h2>USING TECHNOLOGY TO DISCOVER VALUE AND OPPORTUNITY</h2> <Link to="/about">
          <button className="button">About Us</button>
          </Link>
        </div>
      </section>
      <section className="section" style={{marginTop: '5%'}}>
        <div className="center title-section">
          <h1 className="font-38">OUR STRATEGY</h1>
        </div>
        <br />
        <div className="grid grid-3">
          <div className="card">
            <h3>DEVELOPMENT</h3>
            <p>
              We leverage software engineering to build tools and models that provide advanced solutions to understanding the market.              </p>
            <div className="line"></div>
          </div>
          <div className="card">
            <h3>SENTIMENT ANALYSIS</h3>
            <p>
              Our specialized sentiment research tools allow us to gain sense of market fundamentals on each trading day.              </p>
            <div className="line"></div>
          </div>
          <div className="card">
            <h3>VALUE IMPORTANCE</h3>
            <p>
              We stay ahead of the curve by focusing on highly valued companies.            </p>
            <div className="line"></div>
          </div>
          <div className="card">
            <h3>TRAINING</h3>
            <p>
              Those who choose to work with us receive specialized training that covers investing, trading, risk management, the psychology of trading and more.
              </p>
            <div className="line"></div>
          </div>
          <div className="card">
            <h3>MENTORING</h3>
            <p>
              We mentor new team members through a series of lectures and exercises that aim to strengthen the various necessary skills of a trader.              </p>
            <div className="line"></div>
          </div>
          <div className="card">
            <h3>RISK MANAGEMENT </h3>
            <p>
              Our strategies are built using a risk-first  methodology combined with calculations and research that helps predetermine how we should take a position.</p>
            <div className="line"></div>
          </div>
        </div>
      </section>
      <section className="section gap">
        <div className="grid grid-2">
          <div className="side-card" >
            <h1 className="font-38">TRAINING</h1>
            <small>
            Our training program is designed for you to find the best strategies. <br/> We will help you understand the following topics:
            </small>
            <p>
              <ul>
                <li>The Fundamentals of Investing</li>
                <li>Technical Analysis</li>
                <li>Fundamental Analysis</li>
                <li>The Psychology of Trading</li>
                <li>Risk Management</li>
                <li>How to Recognize Chart Patterns</li>
                <li>The Basics of Market Statistics</li>
              </ul>
              </p>
          </div>

          <div className="image">
            <img src="/assets/training.svg" alt="training" width="90%" />
          </div>
        </div>
      </section>
      <div className="separate"></div>
      <section className="section">
        <div className="grid grid-2">
          <div className="image">
            <img src="/assets/mentoring.svg" alt="training" width="90%" className="mentor-image"/>
          </div>
          <div className="side-card" >
            <h1 className="font-38">MENTORING</h1>
            <small>
            Our mentoring program aims to improve your abilities to understand:
            </small>
            <p>
              <ul>
                <li>Investor Psychology</li>
                <li>Trader Psychology</li>
                <li>Market Awareness</li>
                <li>Trader Instincts</li>
                <li>Strategy Development</li>
              </ul>
              </p>
          </div>


        </div>
      </section>
      <div className="separate"></div>
      <section className="section center">
        <div className="center risk-section">
          <h1 className="font-38">RISK MANAGEMENT AT THE CORE</h1>
          <p>
          Our risk management strategy is the number one key to our success. The performance of a team member is based on one's ability to document processes as it relates to risk performance and strategy building; as oppose to P/L (Profit-Loss) factors and quantity.              </p>
        </div>
      </section>

    <Footer/>
    </main>
  );
}