import React from 'react';
import { NavLink } from 'react-router-dom';

export class Nav extends React.Component {
    state = {
        open: false
    }
    render(){
        return <>
               <ul className="nav-desktop">
                <li>
                <NavLink exact to='/'> HOME</NavLink>
                </li>
                <li>
                <NavLink exact to='/about'> ABOUT US</NavLink>
                </li>
                <li>
                <NavLink exact to='/contact'> CONTACT</NavLink>
                </li>
            </ul>

            <div className="nav-mobile-wrapper">
            <div onClick={() => {
                this.setState({ open: !this.state.open})
            }} className="hamburger">
                        <div></div>
                        <div></div>
                        <div></div>
            </div>
            <ul className={"nav-mobile " + (this.state.open ? "open" : "")}>
                <li>
                <NavLink exact to='/'> HOME</NavLink>
                </li>
                <li>
                <NavLink exact to='/about'> ABOUT US</NavLink>
                </li>
                <li>
                <NavLink exact to='/contact'> CONTACT</NavLink>
                </li>
            </ul>
            </div>
        </>
    }
}