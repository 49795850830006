import React from 'react';
import { Footer } from './Footer';
import { Logo } from './Logo';

export const About = ({ nav }) => {
  return (
    <main>
      <Logo/>
      {nav}
      <section className="aboutContainer banner">
        <div className="overlay"></div>
        <br />
        <div className="grid grid-2 gap-5">
          <div className="card-banner">
            <h1 className="font-38">WHO ARE WE</h1>
            <p style={{maxWidth: 370}}>
              Technology and risk management is at the heart of what we do.
              We explore opportunity in the markets that form our economy.
             </p>
          </div>
          <div className="banner-image">
            <img src="/assets/aboutBanner.svg" alt="banner" width="100%" />
          </div>
        </div>
      </section>

      <section className="section gap">
        <div className="grid grid-2">
          <div className="image">
          <img src="/assets/aboutSection.svg" alt="banner" width="100%" />
          </div>

          <div className="side-card" >
            <h1 className="font-38">HERE FOR THE FUTURE</h1>
            <p>
            We are consistently driven to discover 
new strategies while solving technical 
problems. Our proprietary system and 
software allow us to achieve realistic
alpha in the markets. We thrive by using 
a feedback loop to correct errors in 
our digital remote environment.
              </p>
          </div>

        </div>
      </section>
      <div className="gap"></div>
      <Footer/>
    </main>
  );
}