import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Redirect, Switch } from "react-router-dom";
import {Home} from './Home';
import {About} from './About';
import {Contact} from './Contact';
import { Nav } from './Nav';

import './index.css';
import './grid.css';

const navigation = <Nav/>;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Home nav={navigation}/>}
        title={'Home'}
      />
      <Route
        exact
        path="/about"
        render={() => <About nav={navigation}/>}
        title={'About'}
      />
      <Route
        exact
        path="/contact"
        render={() => <Contact nav={navigation}/>}
        title={'Contact'}
      />

      <Redirect to="/"/>
    </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

